<template>
  <div class="async-job-detail">
    <div class="header">
      <a-breadcrumb style="margin-bottom: 10px;" v-if="type !== 'ticket'">
        <a-breadcrumb-item>
          <span @click="goAsyncJobList" style="cursor: pointer">异步执行管理列表</span>
<!--          <router-link :to="{name: 'ASYNC_JOB_LIST'}"></router-link>-->
        </a-breadcrumb-item>
        <a-breadcrumb-item>{{ jobDetail.jobName }}</a-breadcrumb-item>
      </a-breadcrumb>
      <a-button @click="getJobDetail">刷新</a-button>
    </div>
    <a-table :columns="columns" :data-source="jobDetail.taskVOS" :pagination="{size:'small'}" bordered size="small">
      <template #maxExportRecordCount="record">
        <edit-cell :text="record.maxExportRecordCount" @change="onCellChange(record, 'maxExportRecordCount', $event)" v-if="showEditLimit(record)"/>
        <div v-else>{{record.maxExportRecordCount === -1 ? '无限' : record.maxExportRecordCount}}</div>
      </template>
      <template #maxExportTimeoutSec="record">
        <edit-cell :text="record.maxExportTimeoutSec" @change="onCellChange(record, 'maxExportTimeoutSec', $event)" v-if="showEditLimit(record)"/>
        <div v-else>{{record.maxExportTimeoutSec === -1 ? '无限' : record.maxExportTimeoutSec}}</div>
      </template>
      <template #affectRows="record">
        {{record.affectRow || 0}}
      </template>
      <template #action="record">
        <a-button type='link' :disabled="![EXPORT_STATUS.FAIL, EXPORT_STATUS.WAIT_TO_FAIL].includes(record.status)"
                  @click="handleAction('retry', record)" class="retry">重试
        </a-button>
        <a-button type='link' :disabled="[EXPORT_STATUS.RUNNING, EXPORT_STATUS.COMPLETE, EXPORT_STATUS.CANCEL, EXPORT_STATUS.WAIT_TO_CANCEL].includes(record.status)"
                  @click="handleAction('skip', record)" class="skip">跳过
        </a-button>
        <a-button type='link' :disabled="!record.downloadResult"
                  @click="handleAction('download', record)" class="download">下载
        </a-button>
      </template>
      <template #status="record">
        <div style="display: flex;align-items: center">
          <cc-status :type="EXPORT_HEALTH_LEVEL_COLOR[record.status]"/>
          {{ EXPORT_STATUS_I18N[record.status] }}
          <a-popover v-if="record.status === 'FAIL'" placement="bottom" style="margin-left: 5px;">
            <a-icon type="exclamation-circle" />
            <template slot="content">
              {{record.statusMsg}}
            </template>
          </a-popover>
        </div>
      </template>
      <template #sql="record">
        <div class="">
          {{record.exportSql.substr(0, 30)}}
          <span v-if="record.exportSql.length > 30">...</span>
          <a-button type="link" v-if="record.exportSql.length > 30" @click="handleAction('detail', record)">查看更多</a-button>
        </div>
      </template>
    </a-table>
    <a-modal :visible="showExportSql" title="sql详情" @cancel="handleCloseSqlDetailModal">
      {{ exportSql }}
    </a-modal>
    <a-modal :visible="showDownloadModal" title="下载地址" @cancel="handleCloseDownloadModal">
      {{downloadInfo.resultType==="LOCAL_FILE"?"本地下载地址:":"OSS下载地址:"}}
      <div v-if="downloadInfo.resultType==='LOCAL_FILE'" class="download-address">
        机器IP: {{downloadInfo.workerVO.privateIp || downloadInfo.workerVO.publicIp}}
      </div>
      <div class="download-address">
        {{downloadInfo.urlOrFilePath}}
      </div>
      <div class="footer">
        <a-button @click="handleCloseDownloadModal">关闭</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { EXPORT_HEALTH_LEVEL_COLOR, EXPORT_STATUS, EXPORT_STATUS_I18N } from '../../../consts';
import copyMixin from '../../../mixins/copyMixin';
import EditCell from './EditCell.vue';

export default {
  name: 'AsyncJobDetail',
  components: {
    EditCell

  },
  mixins: [copyMixin],
  props: {
    exportJobId: {
      type: Number,
      default: -1
    },
    type: {
      type: String,
      default: 'detail'
    },
    bizType: String,
    setAsyncJobDetail: Function
  },
  data() {
    return {
      EXPORT_STATUS,
      EXPORT_STATUS_I18N,
      EXPORT_HEALTH_LEVEL_COLOR,
      showDownloadModal: false,
      actions: [
        { value: 'skip', label: '跳过' },
        { value: 'retry', label: '重试' },
        { value: 'download', label: '下载' }
      ],
      downloadInfo: {},
      showExportSql: false,
      exportSql: '',
      jobId: '',
      jobDetail: {},
      columns: [
        {
          title: '任务名称',
          dataIndex: 'taskName'
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '影响行数',
          width: 100,
          scopedSlots: { customRender: 'affectRows' }
        },
        {
          title: 'sql语句',
          width: 300,
          scopedSlots: { customRender: 'sql' }
        },
        {
          title: '最大导出数量（次）',
          width: 130,
          scopedSlots: { customRender: 'maxExportRecordCount' }
        },
        {
          title: '最大导出时间（秒）',
          width: 130,
          scopedSlots: { customRender: 'maxExportTimeoutSec' }
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' }
        }
      ]
    };
  },
  created() {
    window.$bus.on('asyncTaskListBack', this.goAsyncJobList);
    if (this.type === 'detail') {
      this.jobId = this.$route.params.id;
    } else {
      this.jobId = this.exportJobId;
    }

    this.getJobDetail();
  },
  methods: {
    goAsyncJobList() {
      if (this.bizType === 'QUERY_CONSOLE') {
        this.setAsyncJobDetail(-1);
      } else {
        this.$router.push({
          name: 'ASYNC_JOB_LIST'
        });
      }
    },
    showEditLimit(record) {
      return ['FAIL', 'INIT', 'UPLOAD_TO_REMOTE_FS_FAIL', 'WAIT_TO_FAIL'].includes(record.status);
    },
    async onCellChange(record, key, value) {
      record[key] = value;
      if (record.preMaxExportRecordCount !== record.maxExportRecordCount || record.preMaxExportTimeoutSec !== record.maxExportTimeoutSec) {
        const res = await this.$services.updateExportTaskLimit({
          data: {
            exportJobId: this.jobId,
            exportTaskId: record.id,
            maxExportTimeoutSec: record.maxExportTimeoutSec,
            maxExportRecordCount: record.maxExportRecordCount
          }
        });

        if (res.success) {
          this.$message.success('参数修改成功');
          await this.getJobDetail();
        }
      }
    },
    handleCloseDownloadModal() {
      this.showDownloadModal = false;
    },
    async handleDownload(record) {
      const res = await this.$services.genDownloadInfo({
        data: {
          exportJobId: this.jobId,
          exportTaskId: record.id
        }
      });

      if (res.success) {
        this.showDownloadModal = true;
        this.downloadInfo = res.data;
      }
      // if (!record) {
      //   this.$message.error('未生成下载文件');
      //   return;
      // }
      // // 创建a标签
      // const link = document.createElement('a');
      // // 设置a标签的href（点击地址）
      // link.href = record.resultFileName;
      // const linkArr = record.resultFileName.split('/');
      // // 设置a标签属性
      // link.setAttribute('download', linkArr[linkArr.length - 1]);
      // // 点击a标签
      //
      // document.body.appendChild(link);
      // link.click();
      // // 移除a标签
      // document.body.removeChild(link);
    },
    handleCloseSqlDetailModal() {
      this.showExportSql = false;
    },
    handleAction(type, record) {
      switch (type) {
        case 'retry':
          this.handleRetryTask(record);
          break;
        case 'detail':
          this.showExportSql = true;
          this.exportSql = record.exportSql;
          break;
        case 'skip':
          this.handleSkipTask(record);
          break;
        case 'download':
          this.handleDownload(record);
          break;
        case 'copy':
          this.copyText(record.resultFileName);
          break;
        default:
          break;
      }
    },
    async getJobDetail() {
      const res = await this.$services.queryExportJob({
        data: { exportJobId: this.jobId }
      });

      if (res.success) {
        res.data.taskVOS.forEach((task) => {
          task.preMaxExportRecordCount = task.maxExportRecordCount;
          task.preMaxExportTimeoutSec = task.maxExportTimeoutSec;
        });
        this.jobDetail = res.data;
      }
    },
    async handleRetryTask(task) {
      const res = await this.$services.retryExportTask({
        data: {
          exportJobId: this.jobId,
          exportTaskId: task.id
        }
      });

      if (res.success) {
        this.$message.success('重试成功');
        await this.getJobDetail();
      }
    },
    async handleSkipTask(task) {
      const res = await this.$services.skipExportTask({
        data: {
          exportJobId: this.jobId,
          exportTaskId: task.id
        }
      });

      if (res.success) {
        await this.getJobDetail();
        this.$message.success('　跳过成功');
      }
    }
  },
  watch: {
    exportJobId(val) {
      console.log('exportJobId');
      this.jobId = val;
      this.getJobDetail();
    }
  },
  destroyed() {
    window.$bus.off('asyncTaskListBack');
  }
};
</script>

<style scoped lang="less">
.async-job-detail {

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.download-address {
  margin-top: 10px;
  border: 1px solid #ccc;
  padding: 5px;
  background: #eee;
}
</style>
