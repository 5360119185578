<template>
  <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper" style="display: flex;align-items: center;">
      <a-input :value="value" @change="handleChange" @pressEnter="check" size="small" style="margin-right: 5px;"/>
      <a-icon
      type="check"
      class="editable-cell-icon-check"
      @click="check"
    />
    </div>
    <div v-else class="editable-cell-text-wrapper">
      {{ value || ' ' }}
      <a-icon type="edit" class="editable-cell-icon" @click="edit" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditCell',
  props: {
    text: [String, Number]
  },
  data() {
    return {
      value: this.text,
      editable: false
    };
  },
  methods: {
    handleChange(e) {
      this.value = e.target.value;
    },
    check() {
      this.editable = false;
      this.$emit('change', this.value);
    },
    edit() {
      this.editable = true;
    }
  }
};
</script>

<style scoped lang="less">
.editable-cell {
  display: flex;;
}
</style>
